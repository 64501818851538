<template>
    <label class="container">
        <input :value="value" v-model="checkedProxy" @change="onChange" type="checkbox">
        <span class="checkmark"/>
        <span class="checkbox__text">
            <slot/>
        </span>
    </label>
</template>

<script>
    export default {
        name: "Checkbox",
        data() {
            return {
                checkedProxy: this.value
            }
        },
        props: {
            value: {
                type: [String, Boolean],
                required: false
            }
        },
        watch: {
            value: {
                handler: function (val, oldVal) {
                    this.checkedProxy = val
                },
                deep: true
            }
        },
        computed: {
            checked: {
                get() {
                    return this.value
                },
                set(val) {
                    this.checkedProxy = val
                }
            }
        },
        methods: {
            onChange: function (e) {
                this.$emit('input');
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .container {
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 !important;
        margin-bottom: 0;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 300;
        color: #000;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 100%;
        width: 100%;
    }

    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14px;
        width: 14px;
        background-color: #fff;
        border: 1px solid #ddd;
        transition: .07s ease-in-out;
    }

    .container:hover input ~ .checkmark {
        background-color: #fff;
    }

    .container input:checked ~ .checkmark {
        background-color: $gold;
        border-color: $gold;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container input:checked ~ .checkmark:after {
        display: block;
    }

    .container .checkmark:after {
        width: 5px;
        height: 8px;
        bottom: 3px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .checkbox__text {
        margin-left: 10px;
        &:empty {
            display: none;
        }
    }
</style>
